<template>
  <div>
    <UITabs class-button="block mb-4" class="flex w-full" :tabs="tabs" v-model="tab"/>

    <h1 class="text-2xl font-semibold text-gray-900">
      Blacklist {{ tabs[tab] }}
    </h1>
    <small class="-mt-1 mb-2 block">{{ information[tabs[tab]] }}</small>

    <KeepAlive>
      <BlacklistWhitelistTable :key="tabs[tab]" :blacklist="tabs[tab].toUpperCase()" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import UITabs from '@/components/UI/Tabs.vue'
import BlacklistWhitelistTable from '@/components/Table/BlacklistWhitelist.vue'

const tabs = computed(() => ['Ritten', 'Enquetes'])
const tab = ref(0)
const information = {
  Ritten: 'Ritten van een blacklister zal een label BLACKLISTED krijgen in het dashboard.',
  Enquetes: 'Er zullen geen enquetes meer gestuurd worden naar deze blacklist',
}
</script>
